// import React, { useState } from "react";
// import blog1 from '../images/blog1.png';
// // Assuming we have these images in our project
// import blog2 from '../images/blog2.png';
// import blog3 from '../images/blog3.png';


// const Blogs = () => {
//   // Track expanded state for each blog separately
//   const [expandedBlogs, setExpandedBlogs] = useState({
//     blog1: false,
//     blog2: false,
//     blog3: false
//   });

//   // Function to toggle a specific blog's expanded state
//   const toggleExpanded = (blogId) => {
//     setExpandedBlogs(prev => ({
//       ...prev,
//       [blogId]: !prev[blogId]
//     }));
//   };

//   // Blog data
//   const blogData = [
//     {
//       id: "blog1",
//       image: blog1,
//       title: "“Patenting the future drugs: How AI is redefining IP in Drug Discovery and development",
//       author: "Dr. Riyaz Syed",
//       date: "Oct 14, 2024",
//       readTime: "5 min read",
//       excerpt: "The rapid development of AI in drug discovery and design has been nothing short of transformative. The past few years have seen groundbreaking AI tools that helped identify new drug candidates and more importantly facilitated their progression through clinical trials.",
//       content: [
//        "https://medium.com/@riyaz_76592/patenting-the-future-drugs-how-ai-is-redefining-ip-in-drug-discovery-and-development-fb0c85c97cfe" 
//       ]
//     },
//     {
//       id: "blog2",
//       image: blog2,
//       title: "Patenting the Future Drugs II: Path to better patenting amidst challenges and changing landscape",
//       author: "Dr. Riyaz Syed",
//       date: "Nov 21, 2024",
//       readTime: "6 min read",
//       excerpt: "In our previous blog, “Patenting the Future Drugs: How AI is Redefining IP in Drug Discovery and Development”, we examined how AI is transforming the pharmaceutical landscape, with 67 AI-generated molecules now advancing through...",
//       content: [
//      "https://medium.com/@riyaz_76592/patenting-the-future-drugs-ii-path-to-better-patenting-amidst-challenges-and-changing-landscape-77ce667f9543"
//       ]
//     },
//     {
//       id: "blog3",
//       image: blog3,
//       title: "Speaking the Language of Molecules: A Primer on Large Language Models (LLMs) in Drug Discovery",
//       author: "Dr. Riyaz Syed",
//       date: "Jan 8, 2025",
//       readTime: "10 min read",
//       excerpt: "Large language models (LLMs) like ChatGPT, Gemini & Copilot have rapidly woven themselves into the fabric of everyday life. In recent years, life sciences too have begun to apply LLMs to some of the most complex challenges in healthcare and chemistry.",
//       content: [
//         "https://medium.com/@riyaz_76592/speaking-the-language-of-molecules-a-primer-on-large-language-models-llms-in-drug-discovery-5b0b3c93f601"
//       ]
//     }
//   ];

//   return (
//     <div className="w-full bg-gradient-to-b from-gray-900 to-black text-white min-h-screen p-8">
//       <h1 className="text-4xl font-bold text-center mb-12">Latest Insights</h1>
      
//       <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//         {blogData.map((blog) => (
//           <div 
//             key={blog.id} 
//             className="bg-gray-800 rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-blue-500/20 hover:shadow-xl"
//           >
//             <div className="overflow-hidden h-64">
//               <img
//                 src={blog.image}
//                 alt={`${blog.title} Cover`}
//                 className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
//               />
//             </div>
            
//             <div className="p-6 space-y-4">
//               <div className="border-l-4 border-blue-500 pl-4">
//                 <h2 className="text-xl font-bold text-white leading-tight line-clamp-2">
//                   {blog.title}
//                 </h2>
//                 <div className="flex flex-wrap items-center mt-2 text-gray-300 text-sm">
//                   <span className="font-medium">{blog.author}</span>
//                   <span className="mx-2">•</span>
//                   <span>{blog.date}</span>
//                   <span className="mx-2">•</span>
//                   <span>{blog.readTime}</span>
//                 </div>
//               </div>
              
//               <div className="text-gray-200 text-base">
//                 <p>{blog.excerpt}</p>
                
//                 {expandedBlogs[blog.id] && (
//                   <div className="mt-4 space-y-4 animate-fadeIn">
//                     {blog.content.map((paragraph, idx) => (
//                       <p key={idx}>{paragraph}</p>
//                     ))}
//                   </div>
//                 )}
//               </div>
              
//               <div className="pt-4">
//   <a
//     href={blog.content[0]} // Use the first URL from content array
//     target="_blank"
//     rel="noopener noreferrer"
//     className="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg shadow-md transition duration-300 flex items-center"
//   >
//     <span>Read More</span>
//     <svg 
//       className="ml-2 w-4 h-4 transition-transform duration-300" 
//       fill="none" 
//       stroke="currentColor" 
//       viewBox="0 0 24 24" 
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
//     </svg>
//   </a>
// </div>

//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Blogs;



import React, { useState, useEffect } from "react";
import blog1 from '../images/blog1.png';
import blog2 from '../images/blog2.png';
import blog3 from '../images/blog3.png';

const Blogs = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [visibleBlogs, setVisibleBlogs] = useState([]);

  // Blog data with same content
  const blogData = [
    {
      id: "blog1",
      image: blog1,
      title: "Patenting the future drugs: How AI is redefining IP in Drug Discovery and development",
      author: "Dr. Riyaz Syed",
      date: "Oct 14, 2024",
      readTime: "5 min read",
      excerpt: "The rapid development of AI in drug discovery and design has been nothing short of transformative. The past few years have seen groundbreaking AI tools that helped identify new drug candidates and more importantly facilitated their progression through clinical trials.",
      url: "https://medium.com/@riyaz_76592/patenting-the-future-drugs-how-ai-is-redefining-ip-in-drug-discovery-and-development-fb0c85c97cfe"
    },
    {
      id: "blog2",
      image: blog2,
      title: "Patenting the Future Drugs II: Path to better patenting amidst challenges and changing landscape",
      author: "Dr. Riyaz Syed",
      date: "Nov 21, 2024",
      readTime: "6 min read",
      excerpt: "In our previous blog, Patenting the Future Drugs: How AI is Redefining IP in Drug Discovery and Development we examined how AI is transforming the pharmaceutical landscape, with 67 AI-generated molecules now advancing through...",
      url: "https://medium.com/@riyaz_76592/patenting-the-future-drugs-ii-path-to-better-patenting-amidst-challenges-and-changing-landscape-77ce667f9543"
    },
    {
      id: "blog3",
      image: blog3,
      title: "Speaking the Language of Molecules: A Primer on Large Language Models (LLMs) in Drug Discovery",
      author: "Dr. Riyaz Syed",
      date: "Jan 8, 2025",
      readTime: "10 min read",
      excerpt: "Large language models (LLMs) like ChatGPT, Gemini & Copilot have rapidly woven themselves into the fabric of everyday life. In recent years, life sciences too have begun to apply LLMs to some of the most complex challenges in healthcare and chemistry.",
      url: "https://medium.com/@riyaz_76592/speaking-the-language-of-molecules-a-primer-on-large-language-models-llms-in-drug-discovery-5b0b3c93f601"
    }
  ];

  // Handle blog selection for expanded view
  const handleSelectBlog = (blogId) => {
    setIsAnimating(true);
    setTimeout(() => {
      setSelectedBlog(selectedBlog === blogId ? null : blogId);
      setIsAnimating(false);
    }, 300);
  };

  // Staggered animation for blog cards on initial load
  useEffect(() => {
    const timer = setTimeout(() => {
      const loadBlogs = async () => {
        for (let i = 0; i < blogData.length; i++) {
          await new Promise(resolve => setTimeout(resolve, 200));
          setVisibleBlogs(prev => [...prev, blogData[i].id]);
        }
      };
      loadBlogs();
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full bg-black text-white min-h-screen py-16 px-4 sm:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-16 text-center">
          <h1 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
            Latest Insights
          </h1>
          <div className="w-24 h-1 bg-blue-500 mx-auto rounded-full"></div>
        </div>
        

  
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {blogData.map((blog, index) => (
              <div
                key={blog.id}
                className={`group bg-gray-800/70 backdrop-blur rounded-xl overflow-hidden shadow-lg border border-gray-700 hover:border-blue-500/50 transition-all duration-500 hover:shadow-blue-500/20 hover:shadow-xl transform hover:-translate-y-2 ${
                  visibleBlogs.includes(blog.id) ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'
                }`}
                style={{ transitionDelay: `${index * 150}ms` }}
                onClick={() => handleSelectBlog(blog.id)}
              >
                <div className="relative overflow-hidden h-56">
                  <img
                    src={blog.image}
                    alt={`${blog.title} Cover`}
                    className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
                  <div className="absolute bottom-4 left-4">
                    <span className="bg-blue-600 text-xs font-bold px-3 py-1 rounded-full text-white">
                      {blog.readTime}
                    </span>
                  </div>
                </div>
                
                <div className="p-6 space-y-4">
                  <div className="border-l-4 border-blue-500 pl-4">
                    <h2 className="text-xl font-bold text-white leading-tight  group-hover:text-blue-400 transition-colors duration-300">
                      {blog.title}
                    </h2>
                    <div className="flex items-center mt-2 text-gray-300 text-sm">
                      <span className="font-medium">{blog.author}</span>
                      <span className="mx-2">•</span>
                      <span>{blog.date}</span>
                    </div>
                  </div>
                  
                  <div className="text-gray-300 text-base line-clamp-3">
                    <p>{blog.excerpt}</p>
                  </div>
                  
                  <a href={blog.url}>
                  <button className="inline-flex items-center text-blue-400 hover:text-blue-300 font-medium transition duration-300 group/btn">
                    <span>Read Blog</span>
                    <svg 
                      className="ml-2 w-4 h-4 transform group-hover/btn:translate-x-1 transition-transform duration-300" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </button>
                  </a>
                </div>
              </div>
            ))}
          </div>

      </div>
    </div>
  );
};

export default Blogs;
