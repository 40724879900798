import React from "react";
import { useEffect } from "react";

const TermsOfUsage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);
  return (
    <div className="bg-black text-white p-6 w-screen min-h-screen ">
      <h1 className="text-3xl font-bold text-center mb-6">TERMS OF USAGE</h1>
      <p className="text-gray-600 text-center mb-4">Last Updated: March 2025</p>

      <div className="bg-black text-white p-6 rounded-lg shadow-md text-justify">
        <h2 className="text-xl font-semibold mb-4">Overview</h2>
        <p className="mb-4">
          The following are the terms of an agreement between you and CENTELLA SCIENTIFIC PRIVATE LIMITED (referred to as CENTELLA hereafter). By accessing, or using this Web site, you acknowledge that you have read, understand, and agree to be bound by these terms and to comply with all applicable laws and regulations, including export and re-export control laws and regulations. If you do not agree to these terms, please do not use this Web site.
          CENTELLA may, without notice to you, at any time, revise these Terms of Use and any other information contained in this Web site. CENTELLA may also make improvements or changes in the products, services, or programs described in this site at any time without notice

        </p>

        <h2 className="text-xl font-semibold mb-4">General</h2>
        <p className="mb-4">
          This Web site contains proprietary notices and copyright information, the terms of which must be observed and followed. Please see the tab entitled “Copyright and trademark information” for related information.
          CENTELLA grants you a non-exclusive, non-transferable, limited permission to access and display the Web pages within this site as a customer or potential customer of CENTELLA provided you comply with these Terms of Use, and all copyright, trademark, and other proprietary notices remain intact. You may only use a crawler to crawl this Web site as permitted by this Web site’s robots.txt protocol, and CENTELLA may block any crawlers at its sole discretion. The use authorized under this agreement is non-commercial in nature (e.g., you may not sell the content you access on or through this Web site.) All other use of this site is prohibited.
          Except for the limited permission in the preceding paragraph, CENTELLA does not grant you any express or implied rights or licenses under any patents, trademarks, copyrights, or other proprietary or intellectual property rights. You may not mirror any of the content from this site on another Website or in any other media. Any software and other materials that are made available for downloading, access, or other use from this site with their own license terms will be governed by such terms, conditions, and notices. Your failure to comply with such terms or any of the terms on this site will result in automatic termination of any rights granted to you, without prior notice, and you must immediately destroy all copies of downloaded materials in your possession, custody or control
        </p>

        <h2 className="text-xl font-semibold mb-4">Rules of Conduct</h2>
        <p>Your use of the CENTELLA website and any related CENTELLA site is subject to all applicable local, state, national, and international laws and regulations. By using this website, you agree not to violate any such laws and regulations. Any attempt to deliberately damage the CENTELLA website is a violation of criminal and civil laws, and CENTELLA reserves the right to seek damages to the fullest extent permitted by law.</p>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Illegal Activities:</strong> You agree not to post or transmit any material or content that violates or infringes on the rights of others, promotes the use of illegal substances or activities, or is otherwise unlawful, threatening, abusive, harassing, defamatory, libelous, derogatory, invasive of privacy or publicity rights, vulgar, obscene, bigoted, hateful, profane, scandalous, pornographic, indecent, or otherwise objectionable. Any such actions that give rise to civil or criminal liability or violate any applicable law are strictly prohibited.</li>
          <li><strong>Impersonation and Misrepresentation:</strong>You may not impersonate another user, use another user's ID, permit someone else to use your ID, attempt to capture or guess other users' passwords, forge headers, or otherwise manipulate identifiers to disguise the origin of any content you transmit. Conducting fraudulent business operations, promoting or concealing unlawful conduct, and other forms of misrepresentation are prohibited.</li>
          <li><strong>Disruption and Harassment:</strong>You may not engage in any activity that restricts or inhibits other users from using or enjoying the CENTELLA website. This includes "hacking," "cracking," "spoofing," or defacing any portions of the website. Additionally, using the CENTELLA website to stalk or harass another person or entity is strictly prohibited.</li>
          <li><strong>Unsolicited Content:</strong>You may not post or transmit any advertising or commercial solicitations, promotional materials related to websites or online services competitive with the CENTELLA website, or software/materials containing viruses, worms, time bombs, Trojan horses, or other harmful components. Posting political campaign materials, chain letters, mass mailings, spam mail, or using any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, "data mine," or in any way reproduce or circumvent the navigational structure or presentation of the CENTELLA website or its content is also prohibited.</li>
          <li><strong>Information Harvesting:</strong>You may not harvest or collect information about website visitors without their express written consent.</li>
        </ul>
        <p className="my-4">By adhering to these rules of conduct, you help ensure a safe and enjoyable experience for all users of the CENTELLA website. Violations of these rules may result in termination of your access to the website and potential legal action</p>

        <h2 className="text-xl font-semibold mb-4">Disclaimer</h2>
        <p className="mb-4">
          CENTELLA SCIENTIFIC PRIVATE LIMITED ("CENTELLA") strives to ensure the accuracy of the information on this website. However, this site may occasionally contain technical inaccuracies or typographical errors. We do not guarantee the accuracy of any posted information. Please ensure you are using the most current pages of this website and verify the accuracy and completeness of the information before making decisions related to services, products, or other matters described on this and related websites.
          If any provision of these Terms of Use is found by a competent judicial authority to be unenforceable, the validity of the remaining provisions will remain unaffected, provided that the unenforceability does not materially affect the parties' rights under these Terms of Use.
        </p>

        <h2 className="text-xl font-semibold mb-4">Confidential Information</h2>
        <p className="mb-4">
          CENTELLA does not wish to receive confidential or proprietary information from you through our website. In the event of a merger, acquisition, or sale of assets involving CENTELLA, we will ensure the confidentiality of your personal information and notify affected users before personal information is transferred or becomes subject to a different privacy policy. We employ encryption to protect your data while in transit and review our information collection, storage, and processing practices, including physical security measures, to prevent unauthorized access to our systems. Access to personal information is restricted to CENTELLA employees, contractors, and agents who need it to process it. Individuals with access are subject to strict contractual confidentiality obligations and may face disciplinary action or termination if they fail to meet these obligations. Your personal information will not be shared with companies, organizations, or individuals outside of CENTELLA.        </p>

        <h2 className="text-xl font-semibold mb-4">Global Availability</h2>
        <p className="mb-4">
          Information published by CENTELLA on the Internet may include references or cross-references to CENTELLA products, programs, and services that are not announced or available in your country. Such references do not imply that CENTELLA intends to announce or make available these products, programs, or services in your country. Please consult your local CENTELLA representative for information on products, programs, and services available to you.        </p>

        <h2 className="text-xl font-semibold mb-4">Business Relationships</h2>
        <p className="mb-4">
          This website may provide links or references to non-CENTELLA websites and resources. CENTELLA makes no representations, warranties, or endorsements regarding any non-CENTELLA websites or third-party resources that may be referenced, accessible from, or linked to any CENTELLA site. CENTELLA is not responsible for any transactions you may enter into with third parties, even if you learn of such parties (or use a link to such parties) from a CENTELLA site. When accessing a non-CENTELLA website, even one that may contain the CENTELLA logo, please understand that it is independent from CENTELLA, and CENTELLA does not control the content on that website. It is your responsibility to take precautions to protect yourself from viruses, worms, Trojan horses, and other potentially destructive programs, and to safeguard your information        </p>

        <h2 className="text-xl font-semibold mb-4">DISCLAIMER OF WARRANTY</h2>
        <p className="mb-4">
          USE OF THIS SITE IS AT YOUR SOLE RISK. ALL MATERIALS, INFORMATION, PRODUCTS, SOFTWARE, PROGRAMS, AND SERVICES ARE PROVIDED "AS IS," WITH NO WARRANTIES OR GUARANTEES WHATSOEVER. CENTELLA EXPRESSLY DISCLAIMS, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL EXPRESS, IMPLIED, STATUTORY, AND OTHER WARRANTIES, GUARANTEES, OR REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY AND INTELLECTUAL PROPERTY RIGHTS. WITHOUT LIMITATION, CENTELLA MAKES NO WARRANTY OR GUARANTEE THAT THIS WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.
          YOU UNDERSTAND AND AGREE THAT IF YOU DOWNLOAD OR OTHERWISE OBTAIN MATERIALS, INFORMATION, PRODUCTS, SOFTWARE, PROGRAMS, OR SERVICES FROM THIS WEBSITE, YOU DO SO AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES THAT MAY RESULT, INCLUDING LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU
        </p>
        <h2 className="text-xl font-semibold mb-4">Limitation of Liability</h2>
        <p className="mb-4">
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL CENTELLA BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES OF ANY TYPE WHATSOEVER RELATED TO OR ARISING FROM THIS WEBSITE OR ANY USE OF THIS WEBSITE, OR OF ANY SITE OR RESOURCE LINKED TO, REFERENCED, OR ACCESSED THROUGH THIS WEBSITE, OR FOR THE USE OR DOWNLOADING OF, OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, OR SERVICES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOST SAVINGS, OR LOSS OF PROGRAMS OR OTHER DATA, EVEN IF CENTELLA IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS EXCLUSION AND WAIVER OF LIABILITY APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED ON CONTRACT, WARRANTY, TORT, OR ANY OTHER LEGAL THEORIES.        </p>
      </div>
    </div>
  );
};

export default TermsOfUsage;
