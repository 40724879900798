import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const AddressBlock = ({ title, address }) => (
  <div className="flex flex-col bg-[#1E1E2F] p-4 rounded-md shadow-md text-white">
    <h3 className="text-lg font-bold text-[#6FC5F0] mb-2">{title}</h3>
    <div className="flex items-start space-x-4">
      <FaMapMarkerAlt size={20} className="mt-1 text-[#6FC5F0]" />
      <p className="text-sm lg:text-base font-medium">{address}</p>
    </div>
  </div>
);

const Contact = () => {
  const form = useRef();
  const addresses = [
    {
      title: 'India Location',
      address:
        'Atal Incubation Centre – CCMB 3rd Floor, Medical Biotechnology Complex, CCMB Annex 2, Genpact Road, IDA Uppal, Habsiguda, Hyderabad – 500039. Telangana. INDIA',
    },
    {
      title: 'US Location',
      address: '150 College Rd, Princeton, New Jersey 08540',
    },
  ];

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_9prjcx8', 'template_g219thm', form.current, 'PFLO8eN2E-5WDK9MJ')
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          alert('Your message has been sent successfully!');
        },
        (error) => {
          console.error('Error sending email:', error.text);
          alert('There was an error sending your message. Please try again later.');
        }
      );
    e.target.reset(); // Reset form after submission
  };

  return (
    <div className="flex flex-col lg:flex-row items-center lg:justify-center  font-nunito min-h-screen text-white bg-gradient-to-r from-[#332E40] via-[#413E4E] to-[#054267]">
      {/* Left Side */}
      <div className="flex-1 flex flex-col relative bottom-24 items-center lg:items-start text-center lg:text-left p-6 lg:p-32 space-y-6">
        <h1 className="text-2xl lg:text-3xl tagline mb-4">GET IN TOUCH</h1>
        {/* <p className="text-2xl lg:text-3xl font-bold text-[#6FC5F0]">
          <span className="block">Convey Your Ideas to Us</span>
          <span className="block">Ideas to Us</span>
        </p> */}
        <p className="text-sm lg:text-base max-w-xs lg:max-w-sm">
          Contact us for questions, technical assistance, or collaboration opportunities via the contact information provided.
        </p>
        <div className="mt-4 space-y-6">
          <div className="flex items-center gap-4 bg-[#1E1E2F] p-4 rounded-md shadow-lg">
            <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-full">
              <FaEnvelope size={20} className="" />
            </div>
            <div>
              <p className="text-sm font-medium">Email</p>
              <p className="text-sm">connect@centella.ai</p>
            </div>
          </div>
          {addresses.map((item, index) => (
            <AddressBlock key={index} title={item.title} address={item.address} />
          ))}
        </div>
      </div>

      {/* Right Side (Contact Form) */}
      <div className="flex-1 flex justify-center relative bottom-24  items-center p-6 lg:p-16 text-gray-700">
        <form ref={form} onSubmit={sendEmail} className="w-full max-w-lg space-y-6">
          <div className="space-y-4">
            <div>
              <label className="block font-semibold tagline text-white text-start">Name</label>
              <input
                name="name"
                type="text"
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
              <div className="w-full lg:w-1/2">
                <label className="block font-semibold tagline text-white text-start">Email</label>
                <input
                  name="email"
                  type="email"
                  className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="w-full lg:w-1/2">
                <label className="block font-semibold tagline text-white text-start">Phone Number</label>
                <input
                  name="phone"
                  type="text"
                  className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
            <div>
              <label className="block font-semibold tagline text-white text-start">Message</label>
              <textarea
                name="message"
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows="4"
                required
              ></textarea>
            </div>
            <div>
              <label className="block font-semibold tagline text-white text-start">Enquiry Type</label>
              <select
                name="enquiry_type"
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="Media inquiries">Media inquiries</option>
                <option value="Investors">Investors</option>
                <option value="Platform">Platform</option>
                <option value="Careers">Careers</option>
              </select>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-transparent hover:bg-[#7467ad] border border-[#7467ad] text-white font-medium w-full py-2 px-4 rounded-lg transition duration-300"
              >
                SUBMIT NOW
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;