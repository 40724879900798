import React, { useState, useEffect } from "react";
import '../style/home.css';

// Import images
import Genai from '../images/Designer (8).png';
import virtual from '../images/virtualscreen-2.png';
import ADMET from '../images/Designer (21).png';
import retro from '../images/Designer (20).png';
import { useNavigate } from "react-router-dom";


// Custom SVG Icons as React components
const Icons = {
    BrainAI: () => (
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="M12 2a4 4 0 0 0-4 4v1H7a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h1v1a4 4 0 0 0 8 0v-1h1a3 3 0 0 0 3-3v-4a3 3 0 0 0-3-3h-1V6a4 4 0 0 0-4-4z"/>
          <path d="M9 10h1"/>
          <path d="M9 14h1"/>
          <path d="M14 10h1"/>
          <path d="M14 14h1"/>
          <path d="M12 18v-4"/>
          <path d="M12 10V6"/>
          <path d="M10 10h4"/>
        </svg>
      ),
      Pill: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="m10.5 20.5 10-10a4.95 4.95 0 1 0-7-7l-10 10a4.95 4.95 0 1 0 7 7Z"/>
          <path d="m8.5 8.5 7 7"/>
        </svg>
      ), Funnel: () => (
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <polygon points="3 4 21 4 14 12 14 19 10 19 10 12 3 4" />
        </svg>
      ),
  Flask: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M10 2v7.31"/>
      <path d="M14 9.3V2"/>
      <path d="M8.5 2h7"/>
      <path d="M14 9.3a6.5 6.5 0 1 1-4 0"/>
      <path d="M5.52 16h12.96"/>
    </svg>
  ),
  GitFork: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="12" cy="18" r="3"/>
      <circle cx="6" cy="6" r="3"/>
      <circle cx="18" cy="6" r="3"/>
      <path d="M18 9v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V9"/>
      <path d="M12 12v3"/>
    </svg>
  ),
  Search: () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="11" cy="11" r="8"/>
      <path d="m21 21-4.3-4.3"/>
    </svg>
  ),
  ShieldTick: () => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    >
      {/* Shield Shape */}
      <path d="M12 2L3 6v6c0 5 3.8 9 9 10 5.2-1 9-5 9-10V6l-9-4z" />
      {/* Tick Mark Inside Shield */}
      <path d="M9 12l2 2 4-4" />
    </svg>
  ),
  ArrowRight: () => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
      <path d="m12 5 7 7-7 7" />
    </svg>
  )
};

const Platform = () => {
    // Animation states
    const [hoveredCard, setHoveredCard] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [animatedItems, setAnimatedItems] = useState([]);
    const navigate = useNavigate();

    // Intersection Observer for scroll-triggered animations
    useEffect(() => {
        setIsVisible(true);
        
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setAnimatedItems(prev => [...prev, entry.target.id]);
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.2 });
        
        // Observe elements with animation classes
        document.querySelectorAll('.animate-on-scroll').forEach(el => {
            observer.observe(el);
        });
        
        return () => observer.disconnect();
    }, []);

    // Platform feature data
    const platformFeatures = [
        {
            id: "genai",
            title: "Generative AI Chem",
            image: Genai,
            icon: <Icons.BrainAI />,
            features: [
                { text: 'GPT-GNN framework for diverse, novel drug-like compounds.', icon: <Icons.ShieldTick /> },
                { text: 'Pocket-conditioned generation for enhanced relevance.', icon: <Icons.ShieldTick /> },
                { text: 'Multi-parametric optimization for improved outcomes.', icon: <Icons.ShieldTick /> },
                { text: 'Multistep validation & Interactive visualization.', icon: <Icons.ShieldTick /> }
            ]            
        },
        {
            id: "retro",
            title: "Retrosynthesis",
            image: retro,
            icon: <Icons.Flask/>,
            features: [
                { text: 'AI-powered synthetic route prediction using Chemical Language Models & Gen AI.', icon: <Icons.ShieldTick /> },
                { text: 'Single & Multi-step modes for synthetic feasibility.', icon: <Icons.ShieldTick /> },
                { text: 'Fast & efficient route discovery with diverse pathways.', icon: <Icons.ShieldTick /> },
                { text: 'Intuitive, no-code interface for easy use.', icon: <Icons.ShieldTick /> }
            ]            
        },
        {
            id: "virtual",
            title: "Virtual Screening",
            image: virtual,
            icon: <Icons.Funnel />,
            features: [
                { text: 'Screens up to 7M compounds for candidate selection.', icon: <Icons.ShieldTick /> },
                { text: 'Adaptive, target-guided predictive VS models.', icon: <Icons.ShieldTick /> },
                { text: 'Benchmarking with known ligands for optimization.', icon: <Icons.ShieldTick /> },
                { text: 'Score-based evaluation for compound ranking.', icon: <Icons.ShieldTick /> }
            ]            
        },
        {
            id: "admet",
            title: "ADMET",
            image: ADMET,
            icon: <Icons.Pill />,
            features: [
                { text: 'Lightning-fast ADMET evaluations via task-parallelization.', icon: <Icons.ShieldTick /> },
                { text: 'GNN-based SOTA models for single-task & multi-task prediction.', icon: <Icons.ShieldTick /> },
                { text: 'Extensive coverage of 49 physicochemical and pharmacokinetic descriptors.', icon: <Icons.ShieldTick /> },
                { text: 'Interactive visualization of predicted properties.', icon: <Icons.ShieldTick /> }
            ]
            
        },

    ];

    

    return (
        <div className="bg-black px-16 text-white min-h-screen font-sans overflow-hidden">
            {/* Header Section with Animated Entry */}
            <div className="container mx-auto px-6 py-16 relative">
                <div className={`transition-all duration-1000 transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'}`}>
                    <h1 className="text-5xl font-bold mb-6 text-center relative">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#6FC5F0] to-[#4A90E2] relative z-10">
                            CASCADE 
                        </span>
                        <div className="absolute inset-0 bg-gradient-to-r from-[#6FC5F0]/5 to-[#4A90E2]/5 blur-xl z-0"></div>
                    </h1>
                </div>
                
                <div className={`transition-all duration-1000 delay-300 transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                    <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-16 text-center">
                        An integrated AI-powered platform accelerating drug discovery from target to lead optimization
                    </p>
                </div>
                
                {/* Floating particles background effect */}
                <div className="absolute inset-0 overflow-hidden pointer-events-none">
                    {[...Array(20)].map((_, i) => (
                        <div 
                            key={i}
                            className="absolute rounded-full bg-gradient-to-r from-[#6FC5F0]/10 to-[#4A90E2]/10"
                            style={{
                                top: `${Math.random() * 100}%`,
                                left: `${Math.random() * 100}%`,
                                width: `${Math.random() * 20 + 5}px`,
                                height: `${Math.random() * 20 + 5}px`,
                                animationDuration: `${Math.random() * 20 + 10}s`,
                                animationDelay: `${Math.random() * 5}s`,
                                opacity: Math.random() * 0.5 + 0.1
                            }}
                        ></div>
                    ))}
                </div>
                
                {/* Platform Feature Cards - 4 in a row with staggered entrance */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {platformFeatures.map((feature, index) => (
                        <div 
                            id={`card-${feature.id}`}
                            key={feature.id}
                            className={`animate-on-scroll bg-gradient-to-b from-[#0c1d2e] to-[#0a2642] rounded-2xl overflow-hidden border border-[#6FC5F0]/20 flex flex-col h-full transition-all duration-500 hover:shadow-[0_10px_30px_-10px_rgba(111,197,240,0.3)] ${animatedItems.includes(`card-${feature.id}`) ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'}`}
                            style={{
                                transitionDelay: `${index * 150}ms`
                            }}
                            onMouseEnter={() => setHoveredCard(feature.id)}
                            onMouseLeave={() => setHoveredCard(null)}
                        >
                            {/* Card Image Container with parallax effect */}
                            <div className="relative pt-[60%] overflow-hidden group">
                                <img 
                                    src={feature.image} 
                                    alt={feature.title} 
                                    className="absolute inset-0 w-full h-full object-cover transition-all duration-700 group-hover:scale-110 transform"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-[#051321] to-transparent opacity-70 group-hover:opacity-50 transition-opacity duration-500"></div>
                                
                                {/* Animated Feature Icon */}
                                <div className={`absolute top-4 right-4 p-3 rounded-full bg-[#6FC5F0] text-[#051321] shadow-lg transform transition-all duration-500 ease-in-out ${hoveredCard === feature.id ? 'scale-110 rotate-12' : ''}`}>
                                    {feature.icon}
                                </div>
                                
                                {/* Card title overlay that reveals on hover */}
                                <div className={`absolute inset-x-0 bottom-0 bg-gradient-to-t from-[#051321] via-[#051321]/80 to-transparent p-4 transform transition-all duration-500 ${hoveredCard === feature.id ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
                                    <h2 className="text-xl text-center font-bold text-white">
                                        {feature.title}
                                    </h2>
                                </div>
                            </div>
                            
                            {/* Card Content with animated feature list */}
                            <div className="p-6 flex-grow flex flex-col">
                                <h2 className="text-xl font-bold mb-4 text-[#6FC5F0] flex items-center lg:hidden">
                                    {feature.title}
                                </h2>
                                <ul className="space-y-4 flex-grow">
                                    {feature.features.map((item, idx) => (
                                        <li 
                                            key={idx} 
                                            className={`flex items-start transform transition-all duration-500 ${hoveredCard === feature.id ? 'translate-x-2' : 'translate-x-0'}`}
                                            style={{
                                                transitionDelay: `${idx * 100}ms`
                                            }}
                                        >
                                            <div className="bg-[#6FC5F0]/20 rounded-full p-2 mr-3 flex items-center justify-center text-[#6FC5F0] transition-all duration-300 group-hover:bg-[#6FC5F0]/30">
                                                <div className={`transform transition-all duration-500 ${hoveredCard === feature.id ? 'rotate-12 scale-110' : ''}`}>
                                                    {item.icon}
                                                </div>
                                            </div>
                                            <span className="text-gray-300  text-sm pt-1">{item.text}</span>
                                        </li>
                                    ))}
                                </ul>
                                <button 
                                    className="mt-6 group bg-gradient-to-r from-[#6FC5F0]/10 to-[#4A90E2]/10 hover:from-[#6FC5F0]/20 hover:to-[#4A90E2]/20 text-[#6FC5F0] py-3 px-4 rounded-lg text-sm font-medium w-full transition-all duration-300 transform hover:scale-[1.02] flex items-center justify-between overflow-hidden relative"
                                >
                                    <span className="relative z-10"> {feature.title}</span>
                                    <div className="absolute inset-0 bg-gradient-to-r from-[#6FC5F0]/0 to-[#4A90E2]/0 group-hover:from-[#6FC5F0]/10 group-hover:to-[#4A90E2]/20 transition-all duration-500 transform translate-x-full group-hover:translate-x-0"></div>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                
                {/* Call to Action Button with pulse effect */}
                <div className="mt-20 text-center animate-on-scroll" id="cta-button">
                    <div className={`transition-all duration-1000 transform ${animatedItems.includes('cta-button') ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                        <div className="relative inline-block">
                            <div className="absolute inset-0 rounded-full bg-gradient-to-r from-[#6FC5F0] to-[#4A90E2] blur-md animate-pulse"></div>
                            <button 
                              onClick={() => navigate('/contact')}
                                className="relative bg-gradient-to-r from-[#6FC5F0] to-[#4A90E2] hover:from-[#4A90E2] hover:to-[#6FC5F0] text-white font-semibold py-3 px-8 rounded-full transition-all duration-500 transform hover:scale-105 active:scale-95 flex items-center group"
                            >
                                <span>Request Platform Demo</span>
                                <span className="ml-2 transition-transform duration-500 transform group-hover:translate-x-1">
                                    <Icons.ArrowRight />
                                </span>
                                <div className="absolute inset-0 bg-white rounded-full opacity-0 transition-opacity duration-300 group-hover:opacity-10"></div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Advanced Animation Styles */}
            <style jsx>{`
                @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
                
                @keyframes fadeInUp {
                    from { opacity: 0; transform: translateY(20px); }
                    to { opacity: 1; transform: translateY(0); }
                }
                
                @keyframes float {
                    0% { transform: translateY(0px) translateX(0px); }
                    25% { transform: translateY(-10px) translateX(10px); }
                    50% { transform: translateY(0px) translateX(20px); }
                    75% { transform: translateY(10px) translateX(10px); }
                    100% { transform: translateY(0px) translateX(0px); }
                }
                
                @keyframes pulse {
                    0% { transform: scale(1); opacity: 0.8; }
                    50% { transform: scale(1.05); opacity: 0.4; }
                    100% { transform: scale(1); opacity: 0.8; }
                }
                
                @keyframes shimmer {
                    0% { background-position: -200% 0; }
                    100% { background-position: 200% 0; }
                }
                
                .animate-float {
                    animation: float 15s ease-in-out infinite;
                }
                
                .animate-pulse {
                    animation: pulse 2s ease-in-out infinite;
                }
                
                .animate-shimmer {
                    background: linear-gradient(90deg, rgba(111,197,240,0.1), rgba(74,144,226,0.2), rgba(111,197,240,0.1));
                    background-size: 200% 100%;
                    animation: shimmer 3s infinite;
                }
                
                .active\:scale-95:active {
                    transform: scale(0.95);
                }
                
                /* Add floating particles animation */
                .absolute {
                    animation: float ease-in-out infinite;
                }
            `}</style>
        </div>
    );
};

export default Platform;

