import React from 'react';
import oncology from '../images/oncology.png';
import diabetes from '../images/cancer.png'
import rare from '../images/drop.png'

const PipelineTable = () => {
  const data = [
    { indication: "Immuno-oncology", target: "NRP1", progress: { enabling: 50 }, icon: oncology },
    { indication: "Oncology", target: "CDK2", progress: { enabling: 50 }, icon: oncology },
    { indication: "Oncology", target: "PAD4", progress: { enabling: 30 }, icon: oncology },
    { indication: "Immuno-oncology", target: "Undisclosed", progress: { enabling: 30 }, icon: oncology },
    { indication: "Type 2 Diabetes", target: "Undisclosed", progress: { enabling: 30 }, icon: diabetes},
    { indication: "Angelman syndrome", target: "Undisclosed", progress: { enabling: 30 }, icon: rare },
  ];

  return (
    <section className="bg-black text-white px-4 sm:px-8 lg:px-24 xl:px-32 font-sofia h-[100vh]">
      <div className="container mx-auto">
        <h3 className="text-5xl font-bold p-2 mb-8 text-[#6FC5F0] text-start">Pipeline</h3>
        
        <p className="text-lg mb-6">
          Our drug discovery pipeline encompasses six active projects, advancing from hit discovery to the preclinical stage. 
          Among these, three are internal initiatives, while the remaining three are collaborative efforts with leading academic and industry partners. 
          Our research spans key therapeutic areas, including oncology, metabolic disorders, and rare diseases. 
          By tackling some of the most challenging targets in the industry, we continuously enhance our AI-driven platforms, optimizing their capabilities to 
          accelerate the development of transformative therapies.
        </p>
        
        <div className="overflow-x-auto">
          <table className="min-w-full bg-black rounded-lg  h-96 w-[20%]  text-lg">
            <thead>
              <tr className="">
                <th className="px-4 py-2 text-center  text-[#6FC5F0]  text-2xl">Disease Indication</th>
                <th className="px-4 py-2 text-center   text-[#6FC5F0] text-2xl">Target</th>
                <th className="px-4 py-2 text-center   text-[#6FC5F0] text-2xl">Discovery</th>
                <th className="px-4 py-2 text-center  text-[#6FC5F0] text-2xl">Lead Optimization</th>
                <th className="px-4 py-2 text-center  text-[#6FC5F0] text-2xl">Ind - Enabling</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="border-b border-gray-800">
                  <td className="px-4 py-2 flex items-center text-center border-r border-gray-800">
                    <div className="flex items-center mr-4 pr-4 border-r border-gray-800">
                      <img src={row.icon} alt={`${row.indication} icon`} className="w-8 h-8 mr-2" />
                    </div>
                    <div className='pl-20 text-2xl text-center'>{row.indication}</div>
                  </td>
                  <td className="px-4 py-2 text-center border-r border-gray-800 text-2xl">{row.target}</td>
                  <td colSpan="3" className="px-4 py-2">
                    <div className="bg-gray-700 h-3.5 rounded-full relative w-full border border-gray-800">
                      <div
                        className="absolute top-0 left-0 h-3 bg-gradient-to-r from-orange-500 to-purple-500 rounded-full"
                        style={{ width: `${row.progress.enabling}%` }}
                      ></div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default PipelineTable;
