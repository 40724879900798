import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PipelineTable from '../components/PipelineTable';
import PublicationsSection from '../components/Publications';
import home from '../images/Designer (5).png';
import KeyFeatures from './KeyFeatures';
import News from './News';
import Collabarators from '../components/Collabarators';
import Footer from '../components/Footer';
import '../style/home.css';
import CaseStudies from '../components/CaseStudies';

import video from '../images/vid.MP4'
import Platform from './Platform';

const HomePage = () => {


  const [currentVideoIndex] = useState(0);


  useEffect(() => {
    // Optionally, you can perform additional effects when video changes
  }, [currentVideoIndex]);


  return (
    <div className="bg-black overflow-x-hidden">
      <Helmet>
        <title>Centella AI | Accelerating Drug Discovery through AI & Machine Learning</title>
        <meta name="description" content="Centella AI revolutionizes drug discovery through a modular AI platform. We leverage Protein & Chemical Language Models to accelerate drug design, lead optimization, and target identification." />
        <meta name="keywords" content="Drug discovery, Drug design, Artificial Intelligence in drug discovery, Centella AI, AI Therapeutics, Rational Drug Design, AI/ML driven drug discovery, Bioinformatics, Informatics, Ligand-Based Drug Design, Structure-Based Drug Design, SBDD, LBDD, Structure-Activity Relationship, High-Throughput Screening, Synergistic Drug Development Strategies, Artificial Intelligence, Deep Learning, Hybrid Drug Discovery, Lead Optimization, Target Identification, Virtual Screening, ADMET, LLMs, Medicinal Chemistry, Retrosynthesis, Generative AI" />
      </Helmet>


      <section className="bg-black text-white px-4 sm:px-8 lg:px-24 xl:px-16 ">
        <div className="container mx-auto flex flex-col-reverse md:flex-row items-center ">

          <div className="w-full px-6">
            <div className="text-xl sm:text-4xl ml-5 md:text-5xl lg:text-6xl font-bold font-sofia leading-tight">
              <span className="block typewriter slide-in bg-clip-text text-transparent bg-gradient-to-r from-[#FF2df7] via-[#5200FF] to-[#00F0FF]">
                Accelerating Drug
              </span>
              <span className="block typewriter slide-in bg-clip-text text-transparent bg-gradient-to-r from-[#FF2df7] via-[#5200FF] to-[#00F0FF]">
                Discovery through
              </span>
              {/* <span></span> */}
              <span className="block typewriter slide-in bg-clip-text text-transparent bg-gradient-to-r from-[#FF2df7] via-[#5200FF] to-[#00F0FF]">
                human and machine
              </span>
              <span className="block typewriter slide-in bg-clip-text text-transparent bg-gradient-to-r from-[#FF2df7] via-[#5200FF] to-[#00F0FF]">
                intelligence
              </span>
            </div>
            <p className="text-lg md:text-xl ml-5 mt-5 max-w-[50ch]">
              A modular AI platform powered by Protein & Chemical Language Models to generate drug-like molecules with desired properties.
            </p>
          </div>
          <div className="md:w-3/4 w-full h-full flex justify-center p-5 md:justify-end md:mt-0 ">
            <img
              src={home}
              alt="capsule"
              className="w-full md:w-[80%] lg:w-[95%] max-w-none h-auto rounded-lg"
            />
          </div>
        </div>
      </section>
      <section className="bg-black text-white relative bottom-10 py-10 px-4 sm:px-8 lg:px-16 xl:px-24">
        <div className="container mx-auto flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 font-sofia px-4">
            <h2 className="text-5xl font-bold mb-6 text-[#6FC5F0]">Why Centella?</h2>
            <p className="text-xl mb-8">
              Centella AI's platform reduces the Design-Make-Test-Analyze (DMTA) cycle time by 25% and cuts costs by 60%. Our AI-driven drug discovery process ensures faster, safer, and more cost-effective results, bringing life-saving treatments to patients quicker.
            </p>
          </div>
          <div className="lg:w-2/2 lg:mt-0 bg-white bg-transparent lg:ml-16">
            <div className="vid-main ">


              {/* <video muted autoPlay loop>

                <source  type='video/webm' src={video} className='h-[100%]'/>
              </video> */}
              <video muted autoPlay loop className='w-full h-[600px] lg:h-[700px] bg-black'>
                <source type="video/webm" src={video}  />
              </video>
            </div>
          </div>
        </div>
      </section>

      <div className=" ">
        <Platform />
      </div>

      <div className="mt-16">
        <KeyFeatures />
      </div>



      <div className="mt-16 ">
        <PipelineTable />
      </div>
      {/* 
      <div className=" ">
        <PublicationsSection />
      </div> */}

      <div className="mt-12 md:px-8">
        <News />
      </div>

      <div className="mt-16 px-4 sm:px-8 lg:px-24 xl:px-32">
        <CaseStudies />
      </div>



      <div className="mt-24 lg:px-24">
        <Collabarators />
      </div>

      <div className="mt-16 ">
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;