import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import BioAisa from '../images/BioAisa.jpeg'
  import SwissBio from '../images/news3.png'
import hans from '../images/news6.png'
import DD from '../images/news5.png'

import '../style/home.css'

const newsImages = [
 BioAisa,
 SwissBio,
 hans,
 DD
];

const newsData = [
  {
    title: "Team Centella At BioAsia 2024",
    description: "Centella AI Therapeutics was excited to be part of BioAsia 2024, where they presented their latest advancements in AI-driven drug discovery. The event provided a unique platform for Centella AI to engage with industry professionals, share insights, and explore potential collaborations that address the evolving challenges in healthcare and medicine. Representing the team, Dr. Riyaz Syed and Dr. Poornachandra Y looked forward to meaningful interactions with attendees. They were keen to discuss how Centella AI's innovative solutions could contribute to overcoming the complexities of modern healthcare. Attendees were invited to visit Centella AI's booth, P31, to explore opportunities for collaboration and learn more about how their cutting-edge technologies are poised to transform life sciences and healthcare.",
    date: "Feb 27, 2024",
  },
  {
    title: "Team Centella At Swiss Biotech Day",
    description: "Centella AI Therapeutics participated in the Swiss BioTech Day in Switzerland. On Day 1, the team had the pleasure of meeting Thomas W., Deputy Head of Economic Promotion at Canton of Zug, and Florin Müller, Head of Swiss Business Hub India. The team also connected with representatives from the Swiss Business Hub India and the Indian Delegation, fostering valuable relationships that could lead to future partnerships.Stationed at Stall 214, Centella AI's team engaged in conversations and explored collaborative opportunities with attendees. The event provided an excellent platform to showcase innovations and discuss the future of AI-driven drug discovery.On Day 2, Centella AI had the privilege of engaging with His Excellency Mr. Mridul Kumar, the Ambassador of India to Switzerland. ",
    date: "April 21-23, 2024",
  },
  {
    title: "Centella AI featured in The Hans India",
    description: "To integrate artificial intelligence into drug discovery, Centella AI Therapeutics is an AI-first drug discovery startup founded by practicing scientists with real-time drug discovery expertise.The discussion covered a range of topics, including flipped structures, entity setups, fundraising, and M&A insights. Through these multi-layered discussions, participating startups gained valuable knowledge on hiring talent, incorporation, franchise tax, go-to-market strategies, and cultural nuances, making it an enriching session that addressed the complexities of expanding into the U.S. market.",
    date: "Jan 29, 2024",
    source: "The Hans India"
  },
  {
    title: "Dr Riyaz Syed in panel discussion on 'AI in healthcare' by Doordarshan India",
    description: "Centella AI was recently featured on DD India's 'India Ideas' prime time show, hosted by Gautam Roy. The episode delved into how Centella is revolutionizing the field of drug discovery through innovative AI technologies. The discussion highlighted Centella's no-code, GenAI/LLM platform, which accelerates pre-clinical drug discovery by reducing DMTA cycles, aiding AI-assisted synthesis, and designing novel IP-worthy drug molecules. The conversation also covered the use of AI in clinical trial design, focusing on identifying and recruiting suitable patients, predicting safety and efficacy, and real-time monitoring to enable faster problem identification and trial adjustments.Link: https://www.youtube.com/watch?v=kZ7jFhpsG8I",
    date: "July 5, 2024",
    source: "FDA News"
  },
];

const NewsList = () => {
   useEffect(() => {
          window.scrollTo(0, 0); // Scrolls to the top when component mounts
        }, []);
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div className='bg-black font-sofia'>
    <motion.div 
      className="bg-black p-4 sm:px-8 lg:px-24 xl:px-32"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <section className="mx-4 md:mx-2">
        <motion.div 
          className="mb-8"
          variants={itemVariants}
        >
           <div className="w-full text-center ">
    <h2 className="text-6xl sm:text-5xl lg:text-6xl font-bold mb-4 text-[#6FC5F0]">
      News & Updates
    </h2>
    <hr className="border-t-2 border-[#6FC5F0] w-full" />
  </div>
        </motion.div>
      </section>

      {/* Cards Section */}
      <div className="space-y-10">
        {newsImages.map((image, index) => (
          <Link 
            key={index} 
            to="/newsdetails"
            state={{
              image: newsImages[index],
              title: newsData[index].title,
              description: newsData[index].description,
              date: newsData[index].date,
              source: newsData[index].source,
            }}
            className="block"
          >
            <motion.div 
              className={`flex flex-col md:flex-row ${index % 2 === 0 ? '' : 'md:flex-row-reverse'}`}
              variants={itemVariants}
              whileHover={{ scale: 1.02 }}
            >
              <div className="flex-shrink-0">
                <motion.img
                  src={image}
                  alt={`News ${index + 1}`}
                  className="w-full h-48 md:w-96 md:h-64 rounded-lg object-cover transform transition-transform"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1.2 }}
                />
              </div>
              <div className="w-full p-4 md:pl-8 text-justify">
                <h2 className="text-lg md:text-2xl font-bold font-sofia text-white">
                  {newsData[index].title}
                </h2>
                <p className="font-sofia  pt-2 py-2 text-sm md:text-base text-white text-justify hover:underline truncate-lines">
                  {newsData[index].description}
                </p>
                <div className="mt-2">
                  <h2 className="text-sm md:text-lg font-sofia text-start font-semibold text-white">
                    <span className="font-sofia">Published on:</span>
                    <span className="font-sofia font-medium p-2">{newsData[index].date}</span>
                  </h2>
                 
                </div>
              </div>
            </motion.div>
            {index < 3 && (
              <hr className="my-8 border-t-2 border-stone-300 w-11/12 mx-auto" />
            )}
          </Link>
        ))}
      </div>
    </motion.div>
  </div>
  );
};

export default NewsList;